export const FirebaseConfig = {
	"projectId": "pickupinaja-989d5",
	"appId": "1:1096181215199:web:c240ff8529e109f58a1e6a",
	"databaseURL": "https://pickupinaja-989d5-default-rtdb.asia-southeast1.firebasedatabase.app",
	"storageBucket": "pickupinaja-989d5.appspot.com",
	"locationId": "us-central",
	"apiKey": "AIzaSyC8K1MILLkQOWN5aYYgrNIXvQ_rcE847JI",
	"authDomain": "pickupinaja-989d5.firebaseapp.com",
	"messagingSenderId": "1096181215199",
	"measurementId": "G-J12S3D8C5Q"
};